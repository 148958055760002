import React from 'react';
import { Link } from 'gatsby';
import External from '../icons/External';

import css from '../css/components/Nav.module.css';

const ListLink = ({ ...props }) => (
  <li>
    <Link to={props.to} activeClassName={css.active}>
      {props.children}
    </Link>
  </li>
);

export default ({ closePopup, ...props }) => (
  <nav role="navigation" className={css.nav}>
    <div className={css.menuToggle}>
      <input type="checkbox" />

      <div className={css.hamburger}>
        <span />
        <span />
        <span />
      </div>

      <ul className={css.menu}>
        <ListLink to="/kameny/">Kameny</ListLink>
        <ListLink to="/projekty/">Projekty</ListLink>
        <ListLink to="/autorka/">Autorka</ListLink>
        <ListLink to="/markety/">Markety</ListLink>
        <ListLink to="/kontakt/">Kontakt</ListLink>
        <li className={css.disabled}>
          <div
            href="https://shop.lisis.cz/"
            target="_blank"
            className={`${css.tooltip} ${css.tooltipBottom}`}
            data-tooltip="Sledujte Instagram a Facebook pro informace ohledně spuštění eshopu."
          >
            Obchod{' '}
            <div className={css.external}>
              <External width={14} height={14} />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
);
